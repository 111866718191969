@import url("https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700&subset=latin-ext");

html {
  position: relative;
  min-height: 100%;
}

body {
  min-height: 100%;
  font-family: "Quicksand", sans-serif;

  ul,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    color: #000;
  }

  p {
    font-size: 15px;
  }

  .btn-default {
    border-radius: 0;
    border: 1px solid #000;
    color: #000;
    transition: all 0.3s;

    &:hover {
      background: #000;
      border-color: #000;
      color: #fff;
    }
  }

  .m-t-5 {
    margin-top: 5px;
  }

  .m-t-10 {
    margin-top: 10px;
  }

  .m-t-15 {
    margin-top: 15px;
  }

  .m-t-20 {
    margin-top: 20px;
  }

  .m-t-25 {
    margin-top: 25px;
  }

  .m-t-30 {
    margin-top: 30px;
  }

  .m-b-5 {
    margin-bottom: 5px;
  }

  .m-b-10 {
    margin-bottom: 10px;
  }

  .m-b-15 {
    margin-bottom: 15px;
  }

  .m-b-20 {
    margin-bottom: 20px;
  }

  .m-b-25 {
    margin-bottom: 25px;
  }

  .m-b-30 {
    margin-bottom: 30px;
  }

  .p-t-5 {
    padding-top: 5px;
  }

  .p-t-10 {
    padding-top: 10px;
  }

  .p-t-15 {
    padding-top: 15px;
  }

  .p-t-20 {
    padding-top: 20px;
  }

  .p-t-25 {
    padding-top: 25px;
  }

  .p-t-30 {
    padding-top: 30px;
  }

  .p-b-5 {
    padding-bottom: 5px;
  }

  .p-b-10 {
    padding-bottom: 10px;
  }

  .p-b-15 {
    padding-bottom: 15px;
  }

  .p-b-20 {
    padding-bottom: 20px;
  }

  .p-b-25 {
    padding-bottom: 25px;
  }

  .p-b-30 {
    padding-bottom: 30px;
  }
}

.news-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  article {
    width: 290px;
    text-align: center;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 10px;
    box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.2);

    .article-inner {
      border: 1px solid #666;
      padding: 10px;

      .news-image {
        height: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        transition: all 0.3s;

        img {
          width: 100%;
          left: 0;
          transition: all 0.3s;
          position: relative;
        }
      }

      p {
        color: @color1;
        font-size: 11px;
        text-transform: uppercase;
        margin-top: 15px;
        font-weight: 400;
      }

      h2 {
        padding: 15px 10px;
        font-size: 22px;

        a {
          font-weight: 400;
          color: #000;
          text-decoration: none;
          transition: color 0.3s;
        }
      }

      .btn-link {
        font-weight: 700;
        font-size: 18px;
        text-decoration: none;
        color: #000;
      }
    }

    &:hover {
      .news-image {
        box-shadow: 0px 4px 7px -3px rgba(0, 0, 0, 0.14);

        img {
          width: 105%;
          left: -2.5%;
        }
      }

      h2 {
        a {
          color: @color1;
        }
      }
    }

    &.article-hidden {
      padding: 0;
      margin: 0;
      visibility: hidden;
    }
  }
}

ul.pagination-nav {
  margin-top: 50px;
  display: flex;
  justify-content: center;

  li {
    margin: 5px;
    width: 40px;
    height: 40px;
    padding: 5px;
    background: #fff;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #000;
      height: 30px;
      width: 30px;
      border: 1px solid #000;
      text-decoration: none;
    }

    &.active {
      background: @color1;

      a {
        color: #fff;
        border: 1px solid #fff;
      }
    }
  }
}

.nav-categories {
  display: flex;
  justify-content: center;
  padding: 30px 0;

  .category {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 15px;
    margin-right: 5px;
    border: 1px solid #000;
    background-color: none;
    transition: all 250ms ease-in-out;
    text-decoration: none;

    &:hover {
      background-color: #000;

      h3 {
        color: #fff;
      }
    }

    h3 {
      font-size: 12px;
      color: #444;
      font-weight: 600;
      text-align: center;
      line-height: 15px;
    }
  }
}

.scroll-to-top {
  background-color: #444;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: fixed;
  z-index: 999;
  bottom: 5%;
  right: 10%;
  display: none;
  transition: all 0.3s;

  &:hover {
    background-color: #000;
  }

  img {
    position: absolute;
    left: 25%;
    top: 25%;
    height: 20px;
    width: auto;
    cursor: pointer;
  }
}

div#section-header {
  padding: 5px 0;

  nav.navbar {
    min-height: auto;
    margin-bottom: 0;
    background: none;
    border-radius: 0;
    border: none;

    .navbar-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .navbar-social-media {
        display: flex;
        align-items: center;
        gap: 10px;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          border: 1px solid #000;

          svg {
            width: 20px;
            fill: #000;
          }
        }
      }
    }

    .navbar-brand {
      height: auto;

      &.brand-mobile {
        display: none;
      }
    }

    ul.navbar-nav {
      li {
        a {
          font-size: 15px;
          text-transform: uppercase;
          color: #000;
          text-decoration: none;
          padding: 10px !important;
        }
      }
    }

    .navbar-toggle {
      padding: 0;
      margin: 40px 15px 0 0;
      border: none;
      border-radius: none;
      opacity: 0.95;
      background: none;

      p {
        font-size: 9px;
        color: #000;
        text-transform: uppercase;
      }

      .spans {
        span {
          width: 28px;
          background-color: #000;
          border-radius: 50px;
          opacity: 0.8;
        }
      }
    }
  }

  .collapse-flexbox {
    margin-bottom: 15px;
    margin-top: 20px;
    border: 1px solid #000;
    display: flex;
    justify-content: space-between;

    &.fryzjerstwo {
      .flexbox-service {
        width: 47%;
        padding: 50px 0;

        h1 {
          font-size: 28px;
        }

        .btn {
          margin-top: 30px;
        }

        &:first-child {
          background: url(../img/backgrounds/fotolia_78605877.jpg) center left;
          background-size: contain;
          background-repeat: no-repeat;
          text-align: right;
        }

        &:last-child {
          background: url(../img/backgrounds/fotolia_112783049.jpg) center right;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }

    &.galeria {
      padding: 30px;

      .flexbox-service {
        width: 30%;
        height: 160px;
        padding: 15px;
        text-decoration: none;

        .service-inner {
          width: 100%;
          height: 100%;
          border: 1px solid #fff;
          background: rgba(0, 0, 0, 0.3);
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          p {
            color: #fff;
            font-size: 26px;
            font-weight: 700;

            span {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

div#section-welcome {
  display: flex;

  .welcome-box {
    width: 50vw;
    display: flex;
    flex-wrap: wrap;

    .box-service {
      height: 25vw;
      width: 50%;
      position: relative;

      .service-padding {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 20px;

        .service-flexbox {
          height: 100%;
          width: 100%;

          .flexbox-inner-padding {
            padding: 10px;
            height: 100%;

            .flexbox-content {
              padding: 30px;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              text-align: center;

              p {
                margin: 20px 0;
              }

              a.btn-link {
                font-weight: 700;
                font-size: 18px;
                text-decoration: none;
                color: #000;
              }
            }
          }
        }
      }

      &.service-hidden {
        visibility: none;
      }
    }

    &:nth-child(1) {
      .box-service {
        &:nth-child(1) {
          background: url(../img/backgrounds/fotolia_107767780.jpg) center;
          background-size: cover;

          .service-padding {
            .service-flexbox {
              .flexbox-inner-padding {
                .flexbox-content {
                  border: 1px solid #fff;
                  background: rgba(0, 0, 0, 0.3);

                  h1 {
                    color: #fff;
                  }

                  p {
                    color: #fff;
                  }

                  a.btn-link {
                    color: #fff;
                  }
                }
              }
            }
          }
        }

        &:nth-child(2) {
          background: url(../img/backgrounds/fotolia_144884627.jpg) center;
          background-size: cover;

          .service-padding {
            .service-flexbox {
              .flexbox-inner-padding {
                .flexbox-content {
                  border: 1px solid #000;
                  background: rgba(255, 255, 255, 0.5);

                  p {
                    color: #333;
                  }

                  a.btn-link {
                    color: #000;
                  }
                }
              }
            }
          }
        }

        &:nth-child(3) {
          background: url(../img/backgrounds/fotolia_118684603.jpg) center;
          background-size: cover;

          .service-padding {
            .service-flexbox {
              .flexbox-inner-padding {
                .flexbox-content {
                  border: 1px solid #000;
                  background: rgba(255, 255, 255, 0.5);

                  p {
                    color: #333;
                  }

                  a.btn-link {
                    color: #000;
                  }
                }
              }
            }
          }
        }

        &:nth-child(4) {
          background: #222;

          .service-padding {
            .service-flexbox {
              .flexbox-inner-padding {
                .flexbox-content {
                  position: relative;

                  .frame {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;
                    border: 2px solid #fff;
                    background-color: transparent;

                    &.blink-animation {
                      animation: blink 3s ease infinite;
                    }
                  }

                  .text {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                    width: 100%;
                  }

                  h1 {
                    color: #fff;
                    font-size: 40px;
                  }

                  p {
                    color: darken(@gray-lighter, 10%);
                    font-size: 17px;
                  }

                  a.telephone {
                    color: @color1;
                    margin-top: 15px;
                    font-size: 17px;
                    text-decoration: none;
                    font-weight: 700;
                  }

                  a.btn-link {
                    color: #fff;
                  }

                  @keyframes blink {
                    from {
                      opacity: 1;
                    }

                    50% {
                      opacity: 0;
                    }

                    to {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &:nth-child(2) {
      background: url(../img/backgrounds/fotolia_127393502.jpg) center;
      background-size: cover;

      .box-service {
        .service-padding {
          .service-flexbox {
            background: #fff;

            .flexbox-inner-padding {
              .flexbox-content {
                border: 1px solid #000;

                p {
                  color: #888;
                }

                a.btn-link {
                  color: #000;
                }
              }
            }
          }
        }
      }
    }
  }
}

div#section-opinion {
  padding: 70px 0;
  position: relative;
  overflow: hidden;

  .img-absolute {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .owl-carousel-opinion {
    .item {
      padding: 30px 20px;
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid #999;
      max-width: 50%;
      margin-left: 50%;

      p {
        font-size: 20px;

        &:first-child {
          font-weight: 500;
        }
      }
    }

    .owl-controls {
      display: none;
    }
  }
}

div#section-news {
  background: url(../img/backgrounds/fotolia_107617232.jpg);
  background-size: cover;
  background-attachment: fixed;
  padding: 30px 0 80px 0;
}

div#kategoria-section-heading {
  .heading-flexbox {
    display: flex;
    align-items: center;

    .flexbox-image {
      min-width: 18vw;
      max-width: 18vw;
      min-height: 18vw;
      max-height: 18vw;
      padding: 15px;
      position: relative;

      .image-inner {
        position: absolute;
        border: 1px solid #000;
        height: 100%;
        width: 100%;
      }
    }

    .flexbox-description {
      padding-left: 70px;

      h2 {
        margin-top: 20px;
      }

      p {
        margin-top: 20px;
      }
    }
  }
}

div#kategoria-section-content {
  padding-bottom: 50px;
  margin-top: 50px;
  background: url(../img/backgrounds/rockywall.png);
  background-attachment: fixed;

  .content-flexbox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .flexbox-service {
      margin-top: 30px;
      width: 300px;
      text-align: center;

      .service-image {
        display: flex;
        align-items: center;
        height: 200px;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
        }

        .image-inner {
          position: absolute;
          width: 90%;
          height: 90%;
          top: 5%;
          right: 5%;
          border: 1px solid #fff;
          background: rgba(0, 0, 0, 0.3);
          transition: background 0.3s;
        }
      }

      h2 {
        font-size: 22px;
        margin-top: 20px;

        a {
          text-decoration: none;
          color: #000;
        }
      }

      .btn-link {
        margin-top: 15px;
        display: block;
        font-weight: 700;
        font-size: 18px;
        text-decoration: none;
        color: @color1;
      }

      &.service-hidden {
        margin: 0;
        visibility: hidden;
      }

      &:hover {
        .service-image {
          display: flex;
          align-items: center;
          height: 200px;
          overflow: hidden;
          position: relative;

          img {
            width: 100%;
          }

          .image-inner {
            position: absolute;
            width: 90%;
            height: 90%;
            top: 5%;
            right: 5%;
            border: 1px solid #fff;
            background: none;
          }
        }
      }
    }
  }
}

div#produkt-section-content {
  padding-bottom: 50px;

  .heading-flexbox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .flexbox-image {
      width: 40%;
      position: relative;

      img {
        width: 100%;
      }

      .image-inner {
        position: absolute;
        border: 1px solid #000;
        height: 100%;
        width: 100%;
        top: 20px;
        left: 20px;
      }
    }

    .flexbox-description {
      width: 50%;

      h2 {
        margin-top: 20px;

        strong {
          color: @color1;
        }
      }

      p {
        margin-top: 20px;
      }

      .btn-link {
        margin-top: 15px;
        display: block;
        font-weight: 700;
        font-size: 18px;
        text-decoration: none;
        color: #000;
      }
    }
  }
}

div#galeria-section-content {
  padding-bottom: 50px;

  .heading-flexbox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .flexbox-image {
      width: 25%;
      position: relative;

      img {
        width: 100%;
      }

      .image-inner {
        position: absolute;
        border: 1px solid #000;
        height: 100%;
        width: 100%;
        top: 20px;
        left: 20px;
      }
    }

    .flexbox-description {
      width: 65%;

      h2 {
        margin-top: 20px;

        strong {
          color: @color1;
        }
      }

      p {
        margin-top: 20px;
      }

      .btn-link {
        margin-top: 15px;
        display: block;
        font-weight: 700;
        font-size: 18px;
        text-decoration: none;
        color: #000;
      }
    }
  }

  .gallery-flexbox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;

    a {
      margin-top: 30px;
      width: 22%;
      height: 150px;
      overflow: hidden;
      display: flex;
      align-items: center;

      img {
        width: 100%;
      }

      &.gallery-hidden {
        margin: 0;
        height: 0;
        visibility: hidden;
      }
    }
  }
}

div#privacy-policy-section-content {
  padding: 30px 0 60px 0;

  .flexbox-description {
    p {
      margin-top: 10px;
    }

    h1 {
      text-align: center;
      margin-bottom: 30px;
    }

    ul {
      padding-left: 17px;
      text-align: left;
    }
  }
}

div#o-nas-section-content {
  padding-bottom: 50px;

  .heading-flexbox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .flexbox-description {
      width: 50%;

      h2 {
        margin-top: 10px;

        strong {
          color: @color1;
        }
      }

      p {
        margin-top: 20px;
      }

      .btn-link {
        margin-top: 15px;
        display: block;
        font-weight: 700;
        font-size: 18px;
        text-decoration: none;
        color: #000;
      }
    }

    .flexbox-image {
      width: 40%;
      position: relative;

      img {
        width: 100%;
      }

      .image-inner {
        position: absolute;
        border: 1px solid #000;
        height: 100%;
        width: 100%;
        top: 20px;
        left: 20px;
      }
    }
  }

  .gallery-flexbox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;

    a {
      margin-top: 30px;
      width: 31%;
      height: 210px;
      overflow: hidden;
      display: flex;
      align-items: center;

      img {
        width: 100%;
      }
    }
  }

  .partners-flexbox {
    margin-top: 50px;

    h2 {
      text-align: center;
      margin-bottom: 15px;
    }

    .owl-carousel-partners {
      .owl-controls {
        display: none;
      }
    }
  }

  .cooperate-flexbox {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      min-width: 450px;
      max-width: 450px;
    }

    .flexbox-description {
      padding-left: 70px;

      p {
        margin-top: 15px;
      }
    }

    &.flexbox-reverse {
      .flexbox-description {
        padding-left: 0;
        padding-right: 70px;
      }
    }
  }
}

div#technologie-section-content {
  padding-bottom: 60px;

  h2 {
    margin-top: 10px;
  }

  .flexbox-wrapper {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }

  .flexbox-description {
    width: 50%;

    h3,
    p {
      margin-top: 20px;
    }

    .btn {
      padding: 8px 15px;
      margin-top: 30px;
      border: 1px solid #000;
      border-radius: 0;
      color: #000;
      font-size: 16px;
      transition: all 250ms ease-in-out;
      text-decoration: none;

      &:hover {
        background-color: #000;
        color: #fff;
      }
    }

    ul {
      padding-left: 17px;
      text-align: left;
    }
  }

  .flexbox-image {
    width: 40%;
    display: flex;
    justify-content: center;

    img {
      width: 80%;
      height: 80%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }
}

div#aktualnosc-section-content {
  padding-bottom: 50px;

  article {
    header {
      text-align: center;

      img {
        border: 1px solid #000;
        padding: 15px;
        max-width: 100%;
      }

      p {
        color: @color1;
        font-size: 11px;
        text-transform: uppercase;
        margin-top: 15px;
        font-weight: 400;
      }

      h2 {
        padding: 15px 10px;
        font-size: 22px;

        a {
          font-weight: 400;
          color: #000;
          text-decoration: none;
          transition: color 0.3s;
        }
      }
    }
  }
}

div#cennik-section-content {
  padding-bottom: 50px;
  position: relative;

  .img-absolute {
    position: fixed;
    z-index: -1;
    opacity: 0.7;
    left: -80px;
    bottom: -45px;
  }

  h1,
  h2 {
    text-align: center;
  }

  h2 {
    margin-top: 20px;
  }

  .table-wrapper {
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    overflow-x: hidden;

    .product-service {
      display: flex;
      padding: 10px;

      &:nth-of-type(odd) {
        background: #eee;
      }

      .heading {
        width: 35%;
        text-align: left;
        margin-right: 30px;
      }

      .product-content {
        width: 65%;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  // .table-responsive {
  // 	margin-top: 15px;

  // 	table {
  // 		tbody {
  // 			tr {
  // 				&:first-child {
  // 					td {
  // 						border: none;
  // 					}
  // 				}

  // 				&:nth-child(odd) {
  // 					background-color: #f0f0f0;

  // 				}

  // 				td {
  // 					border-color: @gray-lighter;

  // 					p {
  // 						font-size: 16px;
  // 					}

  // 					&:nth-child(2) {
  // 						// text-align: right;

  // 						p {
  // 							font-weight: 500;
  // 							color: @color1;

  // 							&:after {
  // 								content: 'PLN';
  // 								font-size: 12px;
  // 								margin-left: 5px;
  // 							}
  // 						}
  // 					}
  // 				}
  // 			}
  // 		}
  // 	}
  // }
}

div#voucher-section-content {
  padding-bottom: 100px;

  .section-background {
    padding: 100px 0;
    width: 100%;
    background: url(../img/backgrounds/rockywall.png);
    background-attachment: fixed;
  }

  .images-wrapper {
    position: relative;
    padding-top: 40px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border: 1px solid #000;

    .heading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -65%);
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        text-align: center;

        strong {
          font-size: 60px;
        }

        span {
          font-size: 30px;
          line-height: 40px;
          margin-top: 10px;
          display: inline-block;
        }
      }

      img {
        width: 300px;
      }

      p {
        font-size: 20px;
        margin-top: 80px;
        font-weight: 600;
      }
    }

    .image {
      width: 45%;
      display: flex;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: 0 100%;
      }

      &:first-of-type {
        justify-content: flex-start;
      }

      &:last-of-type {
        justify-content: flex-end;

        img {
          width: 82%;
        }
      }
    }
  }

  .section-description {
    padding-top: 80px;

    h2 {
      font-size: 40px;
      text-align: center;
      margin-bottom: 40px;
      font-weight: 600;
    }

    p {
      margin-top: 10px;
      text-align: center;
      font-size: 16px;
    }

    ul {
      padding-top: 10px;
      text-align: center;

      li {
        padding: 8px 0;
        font-size: 17px;
        font-weight: 500;
      }
    }
  }

  .section-form {
    padding-top: 40px;

    h3 {
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      line-height: 44px;
    }

    .contact-form {
      width: 60%;
      margin: 40px auto 0px auto;

      .form-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      label {
        padding: 0;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #000;

        &.form-check-label {
          font-size: 14px;
          margin-bottom: 0;
          margin-left: 10px;
        }
      }

      .form-content {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #ccc;
        height: auto;
        width: 100%;

        &:focus {
          border-bottom: 1.25px solid #000;
        }
      }

      .form-check {
        display: flex;

        input.form-check-input {
          width: auto;
        }
      }

      input,
      .form-content {
        padding: 10px 15px 5px 0;
        outline: none;
      }

      .button-send {
        padding: 8px 15px;
        border: 1px solid #000;
        background-color: transparent;
        transition: all 250ms ease-in-out;
        color: #444;
        font-size: 17px;
        font-weight: 600;
        margin-top: 20px;

        &:hover {
          background-color: #000;
          color: #fff;
        }
      }
    }

    .section-account {
      padding-top: 50px;
      width: 60%;
      margin: 0 auto;

      h3 {
        text-align: left;
      }

      p {
        margin-top: 10px;
        font-size: 18px;
      }
    }
  }
}

div#kontakt-section-content {
  h1 {
    color: #000;
    font-size: 35px;
    margin-top: 40px;
  }

  .content-flexbox {
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;

    .google-maps {
      height: 270px;
      overflow: hidden;
      width: 50%;

      iframe {
        width: 100%;
        height: 100%;
        border: none;
      }
    }

    .flexbox-service-wrapper {
      display: flex;
      width: 50%;
    }

    .flexbox-service {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;

      p {
        margin: 15px 0;
        color: #000;
        font-size: 18px;
      }

      a {
        color: @color1;
        font-weight: 500;
        font-size: 18px;
      }
    }

    // form {
    // 	margin-top: 50px;
    // 	width: 50%;

    // 	label {
    // 		text-transform: uppercase;
    // 	}

    // 	input {
    // 		box-shadow: none;
    // 		border: 1px solid #000;
    // 		border-radius: 0;
    // 		height: 45px;
    // 	}

    // 	textarea {
    // 		box-shadow: none;
    // 		border: 1px solid #000;
    // 		border-radius: 0;
    // 	}

    // 	.btn-link {
    // 		font-weight: 700;
    // 		font-size: 18px;
    // 		text-decoration: none;
    // 		color: #000;
    // 	}
    // }
  }
}

div#section-footer {
  background: #333;

  .footer-flexbox {
    display: flex;
    justify-content: space-between;

    .flexbox-service {
      margin-top: 80px;

      .service-heading {
        margin-bottom: 10px;

        p {
          text-transform: uppercase;
          color: #fff;
          font-size: 10px;
          font-weight: 700;
        }
      }

      p {
        color: #999;
        font-weight: 400;
        text-transform: uppercase;
      }

      a {
        font-size: 17px;
        font-weight: 500;
        color: @color1;
        text-decoration: none;
      }

      ul {
        li {
          padding-bottom: 5px;
          padding-top: 5px;

          a {
            color: #999;
            font-size: 15px;
            font-weight: 400;
          }

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            padding-bottom: 0;
            border: none;
          }
        }
      }
    }

    .footer-social-media {
      display: flex;
      align-items: center;
      gap: 10px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border: 1px solid #fff;

        svg {
          width: 20px;
          fill: #fff;
        }
      }
    }
  }

  .alert {
    background: none;
    border: 1px solid #444;
    margin-bottom: 0;
    margin-top: 50px;
    text-transform: uppercase;
    border-radius: 0;
    padding: 20px;

    p {
      font-size: 11px;
      color: #999;
    }

    a {
      color: @color1;
      text-decoration: none;
    }
  }

  .footer-credits {
    margin-top: 50px;
    padding-bottom: 10px;
    background: #333;

    p,
    a {
      color: #777;
      font-size: 13px;
      text-decoration: none;
    }
  }
}

section.section-pro-laser {
  min-height: 525px;
  padding: 70px 0;
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 35% center;

  header {
    p {
      margin-top: 0.5rem;
    }
  }

  .outer {
    width: 40%;
    min-width: 270px;
    max-width: 350px;
    text-align: center;
    background-color: #fff;
    padding: 10px;
    margin-left: auto;

    .inner {
      padding: 30px;
      border: 1px solid #000;

      img {
        width: 100%;
        max-width: 225px;
        display: block;
        margin: 0.5rem auto 0;
      }
    }
  }

  .content {
    margin-top: 2rem;
  }

  .btn-link {
    display: block;
    margin-top: 2rem;
    font-weight: 700;
    font-size: 18px;
    text-decoration: none;
    color: #000;
  }
}

@media (max-width: 1950px) {
  div#cennik-section-content {
    .img-absolute {
      width: 22.5%;
    }
  }
}

@media (max-width: 1370px) {
  div#cennik-section-content {
    .img-absolute {
      width: 20%;
      bottom: -25px;
    }
  }
}

@media (max-width: @screen-md-max) {
  div#section-header {
    padding: 5px 5px 20px;

    nav.navbar {
      .container {
        display: block;
      }

      .navbar-wrapper {
        flex-direction: column;
        justify-content: center;
      }

      .navbar-collapse {
        box-shadow: none;
        border: none;
      }

      ul.navbar-nav {
        li {
          a {
            text-align: right;
            padding: 5px 10px;
          }
        }
      }
    }
  }

  div#section-welcome {
    display: block;

    .welcome-box {
      width: 100%;

      .box-service {
        height: auto;
        min-height: 25vw;

        .service-padding {
          position: relative;
        }
      }
    }
  }

  div#galeria-section-content {
    .gallery-flexbox {
      a {
        width: 30%;
      }
    }
  }

  div#o-nas-section-content {
    .gallery-flexbox {
      a {
        height: 180px;
      }
    }
  }

  div#kontakt-section-content {
    .content-flexbox {
      .flexbox-service {
        p {
          font-size: 16px;
        }

        a {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  div#cennik-section-content {
    .img-absolute {
      display: none;
    }
  }

  .nav-categories {
    flex-wrap: wrap;
    justify-content: center;

    .category {
      margin: 5px;
    }
  }

  div#voucher-section-content {
    .images-wrapper {
      .heading {
        h1 {
          strong {
            font-size: 40px;
          }

          span {
            font-size: 22px;
            line-height: 34px;
          }
        }

        p {
          margin-top: 40px;
        }
      }
    }
  }

  div#kontakt-section-content {
    .content-flexbox {
      flex-direction: column;

      .flexbox-service-wrapper {
        width: 100%;
      }

      .flexbox-service {
        padding: 30px 0;
      }

      .google-maps {
        width: 100%;
      }
    }
  }
}

@media (max-width: @screen-sm-max) {
  div#section-header {
    .collapse-flexbox {
      &.galeria {
        flex-wrap: wrap;

        .flexbox-service {
          width: 100%;

          &:nth-child(n + 2) {
            margin-top: 10px;
          }
        }
      }
    }
  }

  div#section-news {
    &.news-main-page {
      .news-flexbox {
        article {
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }

  div#galeria-section-content {
    .gallery-flexbox {
      a {
        width: 45%;
        height: 200px;
      }
    }
  }

  div#o-nas-section-content {
    .gallery-flexbox {
      a {
        height: 140px;
      }
    }
  }

  div#technologie-section-content {
    .flexbox-wrapper {
      flex-direction: column;
      padding: 30px 0;

      &:nth-child(even) {
        flex-direction: column;
      }
    }

    .flexbox-description {
      width: 100%;
      padding-bottom: 30px;
      text-align: center;
    }

    .flexbox-image {
      width: 100%;
      height: 250px;

      .image-inner {
        top: 10px;
        left: 10px;
      }
    }
  }

  div#voucher-section-content {
    .images-wrapper {
      height: 300px;

      .heading {
        width: 100%;
        transform: translate(-50%, -50%);

        img {
          width: 200px;
        }
      }

      .image {
        display: none;
      }
    }

    .section-form {
      .contact-form,
      .section-account {
        width: 75%;
      }
    }
  }

  div#section-footer {
    .footer-flexbox {
      flex-direction: column;
      text-align: center;

      .flexbox-service {
        margin-top: 50px;
      }

      .footer-social-media {
        justify-content: center;
      }
    }
  }
}

@media (max-width: @screen-xs-max) {
  div#section-welcome {
    .welcome-box {
      .box-service {
        width: 100%;
        min-height: 50vh;

        &.service-hidden {
          display: none;
        }
      }
    }
  }

  div#section-opinion {
    .owl-carousel-opinion {
      .item {
        background: rgba(255, 255, 255, 0.8);
        max-width: 100%;
        margin-left: 0%;
      }
    }
  }

  div#section-news {
    .news-flexbox {
      flex-direction: column;
      align-items: center;

      article {
        max-width: 100%;

        .news-image {
          height: auto;
        }
      }
    }
  }

  div#kategoria-section-heading {
    .heading-flexbox {
      flex-direction: column;

      .flexbox-image {
        min-width: 40vw;
        max-width: 40vw;
        min-height: 40vw;
        max-height: 40vw;
      }

      .flexbox-description {
        margin-top: 30px;
        padding: 0;
        text-align: center;
      }
    }
  }

  div#kategoria-section-content {
    .content-flexbox {
      flex-direction: column;
      align-items: center;

      .flexbox-service {
        width: 300px;
        max-width: 100%;

        .service-image {
          height: auto;
        }

        &:hover {
          .service-image {
            height: auto;
          }
        }
      }
    }
  }

  div#produkt-section-content {
    .heading-flexbox {
      flex-direction: column;

      .flexbox-image {
        width: 350px;
        max-width: 85%;
      }

      .flexbox-description {
        margin-top: 50px;
        width: 100%;
        text-align: center;
      }
    }
  }

  div#galeria-section-content {
    .heading-flexbox {
      flex-direction: column;

      .flexbox-image {
        width: 350px;
        max-width: 85%;
        position: relative;
      }

      .flexbox-description {
        margin-top: 50px;
        text-align: center;
        width: 100%;
      }
    }

    .gallery-flexbox {
      flex-direction: column;
      align-items: center;

      a {
        width: 300px;
        max-width: 100%;
        height: auto;
      }
    }
  }

  div#o-nas-section-content {
    .heading-flexbox {
      flex-direction: column;

      .flexbox-description {
        width: 100%;
      }

      .flexbox-image {
        width: 100%;
        margin-top: 30px;

        img {
          width: 100%;
        }

        .image-inner {
          display: none;
        }
      }
    }

    .gallery-flexbox {
      flex-direction: column;
      align-items: center;

      a {
        width: 400px;
        max-width: 100%;
        height: auto;
      }
    }

    .cooperate-flexbox {
      flex-direction: column-reverse;

      img {
        margin-top: 30px;
        max-width: 100%;
        min-width: auto;
      }

      .flexbox-description {
        text-align: center;
        padding-left: 0px;
      }

      &.flexbox-reverse {
        flex-direction: column;

        .flexbox-description {
          padding-left: 0;
          padding-right: 0px;
        }
      }
    }
  }

  div#kontakt-section-content {
    .content-flexbox {
      .flexbox-service {
        margin: 0 5px;

        p {
          font-size: 14px;
        }

        a {
          font-size: 12px;
        }
      }

      // form {
      // 	text-align: center;
      // 	width: 100%;
      // }
    }
  }

  section.section-pro-laser {
    .outer {
      width: 80%;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (max-width: 430px) {
  .nav-categories {
    flex-direction: column;
  }

  div#section-header {
    padding: 5px 5px 10px;

    nav.navbar {
      .navbar-brand {
        &.brand-mobile {
          display: block;
          padding: 5px 0;
        }

        &.brand-desktop {
          display: none;
        }
      }

      .navbar-toggle {
        margin: 15px 0 0 10px;
      }
    }

    .collapse-flexbox {
      &.fryzjerstwo {
        flex-wrap: wrap;

        .flexbox-service {
          width: 100%;
          padding: 50px 20px;
        }
      }
    }
  }

  div#voucher-section-content {
    padding-bottom: 50px;

    .section-description {
      padding-top: 50px;

      h2 {
        font-size: 36px;
        line-height: 50px;
        margin-bottom: 20px;
      }
    }

    .section-form {
      h3 {
        font-size: 22px;
        line-height: 34px;
      }

      .contact-form,
      .section-account {
        width: 90%;
      }
    }
  }
}
